import styled from 'styled-components/macro'
import { ExitPopup } from 'src/components/Exit/ExitPopup'
import { TopBarUserMenu } from 'src/components/App/TopBarUserMenu'
import { WorkBreakPopup } from 'src/components/WorkBreak/WorkBreakPopup'
import { Header2 as VerbitHeader } from '@verbit-ai/verbit-ui-library'
import { getOrganizationIcon } from 'src/utils/header'
import { TopBarTitle } from './TopBarTitle'
import { PublishHeaderLink } from 'src/components/Publish/PublishHeaderLink'
import { TopBarHelpButton } from './TopBarHelpButton'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'

const UserSection = styled.div`
    display: flex;
    align-items: center;

    > :not(:last-child) {
        margin-right: 50px;
    }
`

const UserProfileLink = styled.div`
    display: flex;
    align-items: center;
`

const StyledHeader = styled(VerbitHeader)`
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 100;
`

interface TopBarProps {
    workerName?: string
    isUserWorking?: boolean
}

export const TopBar = ({ workerName, isUserWorking }: TopBarProps) => {
    const { Logo, Nav, Title } = StyledHeader
    const IconComponent = getOrganizationIcon()
    const { sessionStatus } = useSessionStatus(['sessionStatus.sessionName'])
    const [{ context }] = useAppMachine(['controls'])
    const { controls } = context
    const isSupportChatEnabled = controls?.supportChat?.visible && window.embedded_svc

    return (
        <>
            <StyledHeader>
                <Logo>
                    <IconComponent />
                </Logo>

                {isUserWorking && <TopBarTitle />}
                {!isUserWorking && sessionStatus && (
                    <Title maxWidth={950}>{sessionStatus?.sessionName}</Title>
                )}
                <Nav>
                    {isSupportChatEnabled && <TopBarHelpButton />}
                    {workerName && (
                        <UserSection>
                            {isUserWorking && (
                                <>
                                    <PublishHeaderLink />
                                    <WorkBreakPopup />
                                    <ExitPopup />
                                </>
                            )}

                            <UserProfileLink>
                                <TopBarUserMenu workerName={workerName} />
                            </UserProfileLink>
                        </UserSection>
                    )}
                </Nav>
            </StyledHeader>
        </>
    )
}
