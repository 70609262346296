import { OptionType } from '@verbit-ai/verbit-ui-library'

export type HotkeyType = 'event' | 'role'
export const hotkeyTypeName = {
    event: 'Event',
    role: 'Role',
}

export type Hotkey = {
    id?: string
    type: HotkeyType
    value: string
    name: string // role/event name
    color: string
}

export const hotkeyColors: OptionType[] = [
    { value: '#020219', label: 'Black' },
    { value: '#4E4E5F', label: 'Dark grey' },
    { value: '#4F5A65', label: 'Fiord' },
    { value: '#91919D', label: 'Mid Grey' },
    { value: '#CCCCD1', label: 'Grey Light' },
    { value: '#5A3A44', label: 'Brown' },
    { value: '#8C6A00', label: 'Golden Brown' },
    { value: '#032086', label: 'Dark Blue' },
    { value: '#1460AA', label: 'Demin' },
    { value: '#0000E0', label: 'Medium Blue' },
    { value: '#6EB5FF', label: 'Blue Light' },
    { value: '#006060', label: 'Green' },
    { value: '#007A7C', label: 'Surfie Green' },
    { value: '#00933D', label: 'Medium green' },
    { value: '#6CBE44', label: 'Green light' },
    { value: '#40E0D0', label: 'Aqua' },
    { value: '#B3FFF8', label: 'Aqua Light' },
    { value: '#550055', label: 'Tyrian Purple' },
    { value: '#4A00B2', label: 'Dark Purple' },
    { value: '#7600A8', label: 'Violet' },
    { value: '#443FCC', label: 'Medium Purple' },
    { value: '#B500B5', label: 'Deep Magenta' },
    { value: '#FAD2FC', label: 'Magenta Light' },
    { value: '#C0392B', label: 'Mid Red' },
    { value: '#DB0A5B', label: 'Razzmatazz' },
    { value: '#FF6318', label: 'Orange' },
    { value: '#FF8246', label: 'Medium Blue' },
    { value: '#FFD441', label: 'Yellow' },
    { value: '#FFEAAE', label: 'Yellow Light' },
]
