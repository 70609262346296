import styled from 'styled-components/macro'
import { CaretDownIcon } from '@verbit-ai/icons-library'
import { SettingsTab } from '../../models/settings'
import { isChild, settingsMenuItems } from './menu'
import { theme } from '@verbit-ai/verbit-ui-library'

const Caret = styled(CaretDownIcon)<{ $isOpen: boolean }>`
    display: inline-block;
    vertical-align: middle;
    margin-left: 2px;
    transition: transform 0.3s ease;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`
const StyledMenuList = styled.ul<{ $level: number }>`
    list-style: none;
    padding: 0;
    margin-left: ${({ $level }) => ($level > 1 ? '16px' : '0')};
    width: max-content;
`

const StyledListItem = styled.li<{ $isClickable: boolean; $isSelected: boolean }>`
    cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'initial')};
    font-weight: ${({ $isSelected }) => ($isSelected ? 600 : 'initial')};
`

const StyledMenuTitle = styled.div`
    margin-top: 8px;
    color: ${theme.colors.grey[900]};
`

type SettingsMenuProps = {
    selectedTab: SettingsTab
    handleMenuClick: (selectedTab: SettingsTab) => void
}
export const SettingsMenu = ({ selectedTab, handleMenuClick }: SettingsMenuProps) => {
    const renderMenu = (items: SettingsTab[], level = 1) => (
        <StyledMenuList $level={level}>
            {items.map((item) => {
                const isOpen = isChild(selectedTab.id, item)
                return (
                    <StyledListItem
                        key={item.id}
                        id={item.id}
                        $isClickable={!!item.children}
                        $isSelected={item.id === selectedTab.id}
                        onClick={(e) => {
                            e.stopPropagation()
                            handleMenuClick(item)
                        }}
                    >
                        {item.label}
                        {item.children && <Caret $isOpen={isOpen} />}
                        {item.children && isOpen && renderMenu(item.children, level + 1)}
                    </StyledListItem>
                )
            })}
        </StyledMenuList>
    )

    return (
        <>
            <StyledMenuTitle>SETTINGS</StyledMenuTitle>
            <nav>{renderMenu(settingsMenuItems)}</nav>
        </>
    )
}
