import React, { MouseEventHandler, useCallback } from 'react'
import styled, { css } from 'styled-components/macro'
import { ifProp, palette, prop } from 'styled-tools'

import { Speaker } from 'src/models'
import { VoiceSampleButton } from 'src/components/Common/VoiceSampleButton'
import { getSpeakerRole } from 'src/utils/speaker'

const VOICE_SAMPLE_BUTTON_SIZE = 16
const StyledVoiceSampleButton = styled(VoiceSampleButton)<{ isPlaying: boolean }>`
    flex: 0 0 ${VOICE_SAMPLE_BUTTON_SIZE}px;
    height: ${VOICE_SAMPLE_BUTTON_SIZE}px;
    margin-right: 18px;
    transition: color 250ms ease-in;

    ${ifProp(
        'isPlaying',
        css`
            color: ${palette('blue', 0)};
        `,
    )}
`

const Container = styled.div<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: ${ifProp('disabled', 'default', 'pointer')};

    ${ifProp(
        'disabled',
        css`
            color: ${palette('cloudBlue', 2)};
        `,
        css`
            &:hover ${StyledVoiceSampleButton} {
                color: ${palette('blue', 0)};
            }
        `,
    )}
`

const MainContent = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    padding: 6px 0;
    overflow: hidden;
`

const ColorLabel = styled.div<{ color: string }>`
    height: 29px;
    width: 2px;
    margin-right: 8px;
    border-radius: 1px;
    background-color: ${prop('color')};
`

const Details = styled.div`
    padding-right: 10px;
    overflow: hidden;
`

const InlineText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Name = styled(InlineText)`
    font-size: 14px;
    color: ${palette('navy', 9)};
`

const Role = styled(InlineText)`
    font-size: 13px;
    color: ${palette('cloudBlue', 0)};
`

interface SpeakerCellProps {
    onToggleVoiceSample: (
        speakerId: string,
        sampleId: string,
        isPlaying: boolean,
        e: React.MouseEvent,
    ) => void
    speaker: Speaker
    color: string
    voiceSampleId: string | null
    isVoiceSamplePlaying: boolean
    isVoiceSampleBuffering: boolean
}

export const SpeakerCell = ({
    onToggleVoiceSample,
    speaker,
    color,
    voiceSampleId,
    isVoiceSamplePlaying,
    isVoiceSampleBuffering,
}: SpeakerCellProps) => {
    const onClick = useCallback<MouseEventHandler<HTMLDivElement>>(
        (e) => {
            if (voiceSampleId !== null) {
                onToggleVoiceSample(speaker.id, voiceSampleId, isVoiceSamplePlaying, e)
            }
        },
        [speaker.id, voiceSampleId, isVoiceSamplePlaying, onToggleVoiceSample],
    )

    return (
        <Container key={speaker.id} onClick={onClick} disabled={voiceSampleId === null}>
            <StyledVoiceSampleButton
                isPlaying={isVoiceSamplePlaying}
                isBuffering={isVoiceSampleBuffering}
                isDisabled={voiceSampleId === null}
            />

            <MainContent>
                <ColorLabel color={color} />

                <Details>
                    <Name>{speaker.name}</Name>
                    <Role>{getSpeakerRole(speaker)}</Role>
                </Details>
            </MainContent>
        </Container>
    )
}
