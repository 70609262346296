import { useState } from 'react'
import { Hotkey, HotkeyType, hotkeyTypeName } from 'src/models/hotkeys'
import HotkeyInput from './HotkeyInput'
import { Flex, Stack } from '@chakra-ui/react'
import { Button, Typography } from '@verbit-ai/verbit-ui-library'
import styled from 'styled-components/macro'
import { StyledInputStack } from './HotkeysTab'
import { ReplayIcon } from '@verbit-ai/icons-library'
import { NameInput } from './NameInput'
import { useRoleHotkeys } from 'src/hooks/settings/useRoleHotkeys'

type HotkeysFormProps = {
    onSubmit: (data: Hotkey) => void
    type?: HotkeyType
    hotkeyName?: string
}
const StyledReplay = styled(ReplayIcon)`
    margin: 41px 8px 0;
`

const StyledButton = styled(Button)`
    margin-left: 16px;
    margin-top: 30px;
`

const StyledFlex = styled(Flex)`
    height: 70px;
`

export const HotkeyForm = ({ onSubmit, type = 'role', hotkeyName = '' }: HotkeysFormProps) => {
    const [name, setName] = useState<string>(hotkeyName)
    const [hotkey, setHotkey] = useState<string>()
    const [errors, setErrors] = useState({ name: false, hotkey: false })

    const { focusOnRole } = useRoleHotkeys()

    const handleSubmit = () => {
        name && hotkey && onSubmit({ value: hotkey, name: name, type: type, color: '' })
        setName('')
        setHotkey('')
        focusOnRole(name)
    }

    const handleErrorChange = (fieldId: string, hasError: boolean) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldId]: hasError,
        }))
    }

    const allowSubmit = () => {
        const hasAnyError = Object.values(errors).some((error) => error)
        return !!hotkey && !!name && !hasAnyError
    }

    return (
        <StyledFlex>
            <StyledInputStack>
                <Typography htmlFor={type}>{hotkeyTypeName[type]}</Typography>
                <NameInput
                    type={type}
                    onChange={setName}
                    value={name}
                    onErrorChange={handleErrorChange}
                />
            </StyledInputStack>
            <div>
                <StyledReplay />
            </div>
            <StyledInputStack>
                <Typography htmlFor={'hotkey'}>Keyboard shortcut</Typography>
                <HotkeyInput
                    onChange={setHotkey}
                    initialValue={hotkey}
                    onErrorChange={handleErrorChange}
                    autoFocus={!!hotkeyName}
                />
            </StyledInputStack>
            <Stack>
                <StyledButton
                    variant={'primarySubtle'}
                    onClick={handleSubmit}
                    isDisabled={!allowSubmit()}
                >
                    Add
                </StyledButton>
            </Stack>
        </StyledFlex>
    )
}
