import * as React from 'react'
import { Modal, Button } from '@verbit-ai/verbit-ui-library'

type DeleteHotkeyConfirmationProps = {
    isOpen: boolean
    onClose: (isConfirmed: boolean) => void
}

export const DeleteHotkeyConfirmation = (props: DeleteHotkeyConfirmationProps) => {
    const { isOpen, onClose } = props
    return (
        <Modal isOpen={isOpen} onClose={() => onClose(false)} isCentered>
            <Modal.Body>
                Deleting this role will remove it from your default list of roles. Continue?
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onClose(false)} variant="secondary" mr={3}>
                    No
                </Button>
                <Button onClick={() => onClose(true)} colorScheme="blue" variant="primary">
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
