import styled, { keyframes } from 'styled-components/macro'
import { ifProp, palette, prop } from 'styled-tools'
import { Header } from '../common'
import { Menu, MenuItem, Spinner, Toaster } from '@blueprintjs/core'
import { FormField } from './FormField'
import { AutoSuggestInput } from '../AutoSuggestInput'

export const slideInLeft = keyframes`
    from {
        transform: translateX(100%);
        
    }
    to {
        transform: translateX(0);
    }
`

export const slideOutRight = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`

export const Container = styled.div<{ isClosing: boolean }>`
    position: absolute;
    top: 0;
    right: -1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 22vw;
    min-width: 290px;
    max-width: 422px;
    padding: 0 16px;
    background-color: ${palette('white', 0)};
    animation: ${ifProp('isClosing', slideOutRight, slideInLeft)} 500ms
        cubic-bezier(0.25, 1, 0.5, 1) forwards;
`

export const FormFieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: hidden;
`

export const FormHeader = styled(Header)`
    margin-bottom: -5px;
`

export const FormFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    gap: 8px;
    flex: 1;
    overflow-y: hidden;
    margin-bottom: 32px;
`

export const StyledMenu = styled(Menu)`
    overflow-y: auto;
    max-height: 180px;
    padding: 0;
    border-radius: 4px;
    border: solid 1px ${palette('cloudBlueLight', 0)};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
`

export const StyledMenuItem = styled(MenuItem)`
    align-content: center;
    padding: 10px 8px;
    border-radius: 0;
    font-size: 14px;
    line-height: 16px;
    color: ${palette('navy', 6)};

    .bp4-text-overflow-ellipsis {
        display: flex;
        align-items: center;
    }

    .bp4-icon,
    > svg {
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;
        color: ${palette('grey', 8)};
    }

    &:hover,
    &.bp4-active {
        background-color: ${palette('cloudBlueLight', 2)} !important;
        color: ${palette('navy', 6)} !important;

        .bp4-icon,
        > svg {
            color: ${palette('blue', 0)};
        }
    }
`

export const StyledMenuHeader = styled(StyledMenuItem)`
    pointer-events: none;
    font-weight: 600;
    color: ${palette('greyBlue', 1)};
    border-bottom: 1px solid ${palette('cloudBlueLight', 0)};
`

export const RoleColorLabel = styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 2px;
    background-color: ${prop('color')};
`

export const VoiceSamplesFormField = styled(FormField)`
    flex: 1;
    overflow-y: hidden;
    margin-bottom: 32px;
`

export const LabelFormField = styled(FormField)`
    font-size: 14px;
    font-weight: 400;
    color: ${palette('grey', 10)};
    margin-bottom: 8px;
`

export const StyledFormField = styled(FormField)`
    margin-bottom: 16px;
`

export const VoiceSampleList = styled.div`
    flex: 1;
    overflow-y: auto;
`

export const SubmitNetworkError = styled.div`
    margin-bottom: 25px;
    text-align: center;
    font-size: 14px;
    color: ${palette('red', 0)};
`

export const SubmitSpinner = styled(Spinner)`
    margin-right: 6px;

    svg {
        width: 16px;
        height: 16px;
    }
`

export const StyledAutoSuggestInput = styled(AutoSuggestInput)`
    .bp4-popover-target {
        width: 100%;
        .bp4-input-group {
            input.bp4-input {
                height: auto;
                font-size: 14px;
                line-height: 25px;
                border: solid 1px ${palette('grey', 8)};
            }
        }
    }
`

export const StyledToaster = styled(Toaster)`
    .bp4-toast {
        width: 100%;
        min-width: unset;
    }
`
