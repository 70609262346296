import { Hotkey } from './hotkeys'
import { ComponentType } from 'react'

/**
 Settings state type
 */
export type SettingsState = {
    roles: {
        roleHotkeys?: Hotkey[]
        customRoleName?: string
        focusedRole?: string
    }
}

export const defaultState: SettingsState = {
    roles: { roleHotkeys: undefined, customRoleName: '' },
}

/**
 * Define state actions for settings provider
 */
export enum RolesHotkeysActionTypes {
    SET_ROLE_HOTKEYS = 'set_role_hotkeys',
    SET_CUSTOM_ROLE = 'set_custom_role',
    SET_FOCUSED_ROLE = 'set_focused_role',
}
type SetRoleHotkeysAction = {
    type: RolesHotkeysActionTypes.SET_ROLE_HOTKEYS
    roleHotkeys?: Hotkey[]
}
type SetCustomRoleAction = {
    type: RolesHotkeysActionTypes.SET_CUSTOM_ROLE
    customHotkey: string
}
type SetFocusedRoleAction = {
    type: RolesHotkeysActionTypes.SET_FOCUSED_ROLE
    focusedRole: string
}

export type SettingAction = SetRoleHotkeysAction | SetCustomRoleAction | SetFocusedRoleAction

export type SettingsTab = {
    id: string
    label: string
    title?: string
    description?: string
    component?: ComponentType
    children?: SettingsTab[]
}
