import { useEffect } from 'react'
import { Node } from 'slate'
import { ReactEditor, useSlate } from 'slate-react'
import { useEventsMarking } from 'src/state/EventsProvider'

export default function useEditorEvents() {
    const editor = useSlate()
    const { targetIndex, setTargetIndex } = useEventsMarking()

    // Scroll to the target index when it changes, triggered by user's target selection from side panel
    useEffect(() => {
        // explicitly check for undefined value only because 0 is a valid index
        // so we cannot use !targetIndex which will include undefined, null, 0, false, "" (empty string), or NaN
        // because all of these values are falsy.
        if (targetIndex === undefined) return
        const el = ReactEditor.toDOMNode(editor, Node.get(editor, [targetIndex]))
        el.scrollIntoView({ block: 'center', inline: 'nearest' })

        // set the target index to undefined after scrolling to it
        // to allow user clicking the same target again
        // this eliminates the feeling of target being stuck
        setTimeout(() => {
            setTargetIndex && setTargetIndex(undefined)
        }, 1000)
    }, [targetIndex, editor, setTargetIndex])
}
