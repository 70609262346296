import { useState } from 'react'
import { Modal } from '@verbit-ai/verbit-ui-library'
import styled from 'styled-components/macro'
import { SettingsMenu } from './SettingsMenu'
import { SettingsTab } from 'src/models/settings'
import { defaultTabId, getTabById } from './menu'
import { theme } from '@verbit-ai/verbit-ui-library'

const StyledModalDescription = styled.div`
    font-size: 14px;
    color: ${theme.colors.grey[900]};
    font-weight: 400;
    margin-bottom: 16px;
    padding: 0;
`
const StyledModalTitle = styled.h1`
    font-size: 18px;
    padding: 0;
    background: ${theme.colors.gradient.blueShade};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
const ModalWrapper = styled.div`
    display: flex;
    height: 100%;

    & > .menu-column {
        padding: 16px 24px;
        background: ${theme.colors.grey[1800]};
        border-radius: ${theme.radii.md};
    }

    & > .tab-column {
        padding: 16px 32px 24px;
    }
`

type UserSettingsProps = {
    isOpen: boolean
    onClose: () => void
    selectedTabId?: string
}

export const UserSettings = (props: UserSettingsProps) => {
    const { isOpen, onClose, selectedTabId = defaultTabId } = props
    const [selectedTab, setSelectedTab] = useState<SettingsTab>(getTabById(selectedTabId)!)
    const Component = selectedTab?.component

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="5xl">
            <ModalWrapper>
                <div className="menu-column">
                    <SettingsMenu selectedTab={selectedTab} handleMenuClick={setSelectedTab} />
                </div>
                <div className="tab-column">
                    <Modal.CloseButton />
                    <StyledModalTitle>{selectedTab.title}</StyledModalTitle>
                    <StyledModalDescription>{selectedTab.description}</StyledModalDescription>

                    <div>{selectedTab && <>{Component && <Component />}</>}</div>
                </div>
            </ModalWrapper>
        </Modal>
    )
}
