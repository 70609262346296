import { useSlateStatic } from 'slate-react'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { useExhibits } from 'src/state/ExhibitsProvider'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'
import { appHotkeys } from 'src/utils/hotkeys'

export default function useEditorExhibits() {
    const editor = useSlateStatic()
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const { setIsExhibitPopupVisible } = useExhibits()

    const handleExhibitShortcut = (e: KeyboardEvent) => {
        if (isRealTimeReadOnly) return
        if (!editor.selection) return
        setIsExhibitPopupVisible(true)
    }

    useMousetrap(appHotkeys.openExhibit, handleExhibitShortcut, {
        preventDefault: true,
        stopImmediatePropagation: true,
    })
}
