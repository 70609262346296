import { SettingsTab } from '../../models/settings'
import { HotkeysTab } from './Hotkeys/HotkeysTab'

export const settingsMenuItems: SettingsTab[] = [
    {
        id: 'keyboard_shortcuts',
        label: 'Keyboard Shortcuts',
        children: [
            {
                id: 'roles_hotkeys',
                label: 'Roles',
                title: 'Roles Keyboard Shortcuts',
                description:
                    'Define roles and assign keyboard shortcuts for seamless control during sessions',
                component: HotkeysTab,
            },
        ],
    },
]
export const defaultTabId = 'roles_hotkeys'

export const isChild = (id: string, tab: SettingsTab): boolean => {
    if (tab.id === id) {
        return true
    }

    if (!tab.children) {
        return false
    }

    let result = false
    for (const child of tab.children) {
        result = result || isChild(id, child)
    }

    return result
}

export const getTabById = (id: string, root = settingsMenuItems): SettingsTab | null => {
    for (const tab of root) {
        if (tab.id === id) {
            return tab
        }
        if (tab.children) {
            const foundTab = getTabById(id, tab.children)
            if (foundTab) {
                return foundTab
            }
        }
    }
    return null
}
