import React from 'react'
import styled from 'styled-components/macro'
import { useUnmount } from 'react-use'

import { getDefaultVoiceSampleId, getSpeakerColor, isVoiceSamplePlaying } from 'src/utils/speaker'
import { useSpeakers } from 'src/state/SpeakersProvider'
import {
    useSpeakerVoiceSamplePlayer,
    useToggleSpeakerVoiceSample,
} from 'src/state/SpeakerVoiceSamplePlayerProvider'

import { Table, TableColumn } from '../common'
import { SpeakerCell } from './SpeakerCell'

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 30px;
    font-size: 14px;
`

export function SpeakersIntroductionStep() {
    const { speakers } = useSpeakers()
    const { currentPlayingVoiceSample, ...speakerVoiceSamplePlayer } = useSpeakerVoiceSamplePlayer()
    const toggleSpeakerVoiceSample = useToggleSpeakerVoiceSample()

    useUnmount(speakerVoiceSamplePlayer.pause)

    return (
        <Table>
            <TableColumn>
                <Grid>
                    {speakers.map((speaker) => {
                        const defaultSampleId = getDefaultVoiceSampleId(speaker.samples)
                        const isPlaying = isVoiceSamplePlaying(
                            speaker.id,
                            defaultSampleId,
                            currentPlayingVoiceSample,
                        )
                        const color = getSpeakerColor(speaker)

                        return (
                            <SpeakerCell
                                key={speaker.id}
                                onToggleVoiceSample={toggleSpeakerVoiceSample}
                                speaker={speaker}
                                color={color}
                                voiceSampleId={defaultSampleId}
                                isVoiceSamplePlaying={isPlaying}
                                isVoiceSampleBuffering={
                                    isPlaying && speakerVoiceSamplePlayer.isBuffering
                                }
                            />
                        )
                    })}
                </Grid>
            </TableColumn>
        </Table>
    )
}
