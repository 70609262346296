import { isEmpty } from 'lodash/fp'
import { keyBy } from 'lodash/fp'

import { Speaker, SpeakerJSON, SpeakerVoiceSample } from 'src/models'
import { CurrentPlayingVoiceSample } from 'src/state/SpeakerVoiceSamplePlayerProvider'

export const getDefaultVoiceSampleId = (samples: { [id: string]: SpeakerVoiceSample }) => {
    if (isEmpty(samples)) {
        return null
    }

    const sampleList = Object.values(samples)
    return sampleList.find(({ isDefault }) => isDefault)?.id ?? sampleList[0].id
}

export const isVoiceSamplePlaying = (
    speakerId: string,
    sampleId: string | null,
    currentPlayingSpeakerVoiceSample: CurrentPlayingVoiceSample,
) => {
    if (sampleId === null) {
        return false
    }

    return (
        currentPlayingSpeakerVoiceSample?.speakerId === speakerId &&
        currentPlayingSpeakerVoiceSample?.sampleId === sampleId
    )
}

export const getSpeakerColor = (speaker: Speaker) => {
    return speaker.hotkey?.color || 'black'
}

export const getSpeakerRole = (speaker: Speaker) => {
    return speaker.hotkey?.name || speaker.role
}

export const toSpeakersResponse = (speakers: SpeakerJSON[]): Speaker[] => {
    return speakers.map(({ created_at, samples, ...speaker }) => ({
        ...speaker,
        samples: keyBy(
            'id',
            samples.map(({ is_default, ...sample }) => ({
                ...sample,
                isDefault: is_default,
            })),
        ),
        createdAt: created_at,
    }))
}
