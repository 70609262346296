import { theme } from '@verbit-ai/verbit-ui-library'
import styled from 'styled-components/macro'
import { AlertCircleIcon } from '@verbit-ai/icons-library'

const StyledError = styled.span`
    font-size: 12px;
    color: ${theme.colors.red[400]};
`
const StyledErrorIcon = styled(AlertCircleIcon)`
    display: inline-block;
    vertical-align: middle;
    margin-right: 2px;
`
const StyledWrapper = styled.div`
    margin-top: -14px;
`

type InputErrorProps = {
    error: string
}
export const InputError = ({ error }: InputErrorProps) => {
    return (
        <StyledWrapper>
            <StyledErrorIcon color={theme.colors.red[400]} />
            <StyledError>{error}</StyledError>
        </StyledWrapper>
    )
}
