import { Editor } from 'slate'
import LogRocket from 'logrocket'

import { getEnv } from 'src/utils/env'

export const withLogger = (editor: Editor) => {
    const { onChange } = editor

    if (getEnv() !== 'dev') {
        editor.onChange = () => {

            LogRocket.log(Date.now(), 'Editor Content Change', {
                selection: editor.selection,
                operations: editor.operations,
            })

            onChange()
        }
    }

    return editor
}
