import { useEffect, useReducer, useRef } from 'react'
import { Editor, RangeRef } from 'slate'
import { useSlateStatic } from 'slate-react'
import { LiveEditsEditor } from 'src/components/Editor/plugins/withLiveEdits/LiveEditsEditor'
import { TranscriptWebsocketMessage } from 'src/models'
import { useAppEvents } from 'src/state/AppEventsProvider'
import { Block } from 'src/components/Editor/plugins/withTranscript'
import { legalAnnotationsStore } from 'src/state/LegalAnnotationsProvider'
import { useSpeakersById } from 'src/state/SpeakersProvider'
import { COLLOQUY_LEGAL_ANNOTATION } from 'src/models/legal'
import { useLiveCriticalIssueHandler } from '../useLiveCriticalIssueHandler/useLiveCriticalIssueHandler'
import { getSpeakerRole } from 'src/utils/speaker'

type UseLiveEditorOptions = {
    allowedEventTypes?: TranscriptWebsocketMessage['type'][]
    allowLegalAnnotationAutomation?: boolean
    lastWebsocketRangeRef: React.MutableRefObject<RangeRef | null>
    lastUpdateHighlightTimeoutMs?: number
}

const COLLOQUY_ROLES = ['OTHER', 'COURT REPORTER', 'VIDEOGRAPHER', 'INTERPRETER']

export function useLiveEditor(options: UseLiveEditorOptions) {
    const editor = useSlateStatic()
    const websocketClient = useAppEvents()
    const [, triggerRenderUpdate] = useReducer((x) => x + 1, 0)
    const speakersById = useSpeakersById()

    const onError = useLiveCriticalIssueHandler()

    const lastMessageTimeoutIdRef = useRef<NodeJS.Timeout | null>(null)
    useEffect(() => {
        if (!websocketClient) {
            return
        }

        const shouldHandleEvent = (event: TranscriptWebsocketMessage) =>
            options.allowedEventTypes ? options.allowedEventTypes.includes(event.type) : true

        const handleTranscriptEvent = (event: TranscriptWebsocketMessage) => {
            if (!shouldHandleEvent(event)) {
                return
            }

            try {
                const ref = LiveEditsEditor.mergeIncomingEvent(editor, event)
                if (event.type === 'new') {
                    // set annotation if speaker exists on new messages
                    if (ref.current && options.allowLegalAnnotationAutomation) {
                        for (const [block, blockIndex] of Editor.nodes(editor, {
                            at: ref.current,
                            match: Block.isBlock,
                        })) {
                            const speaker = speakersById[block?.speakerId ?? '']
                            const speakerRole = speaker && getSpeakerRole(speaker).toUpperCase()

                            if (
                                block.speakerId &&
                                legalAnnotationsStore.legalAnnotationsBySpeaker[block.speakerId]
                            ) {
                                Block.setLegalAnnotation(
                                    editor,
                                    blockIndex,
                                    legalAnnotationsStore.legalAnnotationsBySpeaker[
                                        block.speakerId
                                    ],
                                )
                            } else if (
                                block.speakerId &&
                                !legalAnnotationsStore.legalAnnotationsBySpeaker[block.speakerId] &&
                                COLLOQUY_ROLES.includes(speakerRole)
                            ) {
                                Block.setLegalAnnotation(
                                    editor,
                                    blockIndex,
                                    COLLOQUY_LEGAL_ANNOTATION,
                                )
                            }
                        }
                    }

                    if (options.lastWebsocketRangeRef) {
                        options.lastWebsocketRangeRef.current?.unref()
                    }
                    options.lastWebsocketRangeRef.current = ref

                    if (lastMessageTimeoutIdRef.current) {
                        clearTimeout(lastMessageTimeoutIdRef.current)
                    }
                    lastMessageTimeoutIdRef.current = setTimeout(() => {
                        options.lastWebsocketRangeRef.current = null
                        triggerRenderUpdate()
                    }, options.lastUpdateHighlightTimeoutMs || 1_500)
                } else {
                    ref?.unref()
                }
            } catch (err: any) {
                onError(err)
            }
        }

        websocketClient.on('transcript', handleTranscriptEvent)
        return () => {
            websocketClient.off('transcript', handleTranscriptEvent)
        }
    }, [
        editor,
        options.allowedEventTypes,
        options.lastUpdateHighlightTimeoutMs,
        options.lastWebsocketRangeRef,
        websocketClient,
        speakersById,
        options.allowLegalAnnotationAutomation,
        onError,
    ])
}
