import { useEffect, useCallback } from 'react'
import Mousetrap from 'mousetrap'
// Needed for Mousetrap.prototype.globalBind which is not exported from the mousetrap package directly
import 'mousetrap/plugins/global-bind/mousetrap-global-bind'
import { useAnalytics } from 'src/analytics'
import Logrocket from 'logrocket'

export type MousetrapCallback = (e: KeyboardEvent, combo: string) => unknown

export interface UseMouseTrapOptions {
    action?: 'keypress' | 'keydown' | 'keyup'
    preventDefault?: boolean
    stopPropagation?: boolean
    stopImmediatePropagation?: boolean
    allowRepeat?: boolean
    condition?: boolean
    label?: string
    analyticsEnabled?: boolean
}

export function useMousetrap(
    keys: string | string[] | undefined,
    callback: MousetrapCallback,
    {
        action,
        preventDefault,
        stopPropagation,
        stopImmediatePropagation,
        allowRepeat = false,
        condition = true,
        label,
        analyticsEnabled = true,
    }: UseMouseTrapOptions = {},
) {
    const analytics = useAnalytics()

    const cachedCallback = useCallback<MousetrapCallback>(
        (e, combo) => {
            Logrocket.log('Mousetrap Hotkey Pressed: ', combo)
            preventDefault && e.preventDefault()
            stopPropagation && e.stopPropagation()
            stopImmediatePropagation && e.stopImmediatePropagation()

            if (allowRepeat || !e.repeat) {
                callback(e, combo)

                if (analyticsEnabled) {
                    analytics?.sendKeyboardShortcutTrigger(label ?? 'Unspecified', combo)
                }
            }
        },
        [
            callback,
            preventDefault,
            stopPropagation,
            stopImmediatePropagation,
            allowRepeat,
            label,
            analyticsEnabled,
            analytics,
        ],
    )

    useEffect(() => {
        if (!keys) return

        if (condition) {
            Mousetrap.bindGlobal(keys, cachedCallback, action)
        }

        return () => {
            Mousetrap.unbind(keys)
        }
    }, [keys, cachedCallback, action, condition])
}
