import { OptionType, Select } from '@verbit-ai/verbit-ui-library'
import { FullStaffIcon } from '@verbit-ai/icons-library'
import { SelectOnChangeOptionType } from '@verbit-ai/verbit-ui-library/dist/components/Select'
import React, { useState } from 'react'

type ColorSelectProps = {
    getColorOptions: (selectedColor?: string) => OptionType[]
    selectedColor?: string
    onChange: (option: SelectOnChangeOptionType) => void
}
export const ColorSelect = ({ getColorOptions, selectedColor, onChange }: ColorSelectProps) => {
    const [selected, setSelected] = useState(selectedColor)
    const [colorOptions, setColorOptions] = useState(getColorOptions(selectedColor))
    const options: OptionType[] = colorOptions.map((option) => {
        return {
            ...option,
            icon: <FullStaffIcon color={option.value} />,
        }
    })

    const handleChange = (option: SelectOnChangeOptionType) => {
        setSelected(option ? (option as OptionType).value : undefined)
        onChange(option)
    }

    return (
        <Select
            options={options}
            menuPlacement="top"
            onMenuOpen={() => {
                setColorOptions(getColorOptions(selectedColor))
            }}
            isFullWidth
            isClearable
            placeholder={'Select color'}
            value={options.find((option) => option.value === selected)}
            onChange={handleChange}
        />
    )
}
