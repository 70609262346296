import React, { createContext, Reducer, useMemo, useReducer } from 'react'
import {
    defaultState,
    RolesHotkeysActionTypes,
    SettingAction,
    SettingsState,
} from 'src/models/settings'

/* =================================================
 Reducer creation to manage context state
 =================================================== */

function settingsReducer(state: SettingsState, action: SettingAction): SettingsState {
    switch (action.type) {
        case RolesHotkeysActionTypes.SET_ROLE_HOTKEYS:
            return { ...state, roles: { ...state.roles, roleHotkeys: action.roleHotkeys } }
        case RolesHotkeysActionTypes.SET_CUSTOM_ROLE:
            return { ...state, roles: { ...state.roles, customRoleName: action.customHotkey } }
        case RolesHotkeysActionTypes.SET_FOCUSED_ROLE:
            return { ...state, roles: { ...state.roles, focusedRole: action.focusedRole } }
        default:
            return state
    }
}

/* =================================================
 Context creation:
 Settings data and state provider to use all over Trax.
 Implemented as context combined with reducer to ensure each consumer get only the setting part it needs
 return type: contextProviderValue
 =================================================== */
type SettingsProviderProps = {
    children: React.ReactNode
}
type contextProviderValue = {
    settingsState: SettingsState
    dispatch: React.Dispatch<SettingAction>
}
export const SettingsContext = createContext({} as contextProviderValue)

export const SettingsProvider = ({ children }: SettingsProviderProps) => {
    const [settingsState, dispatch] = useReducer(settingsReducer, defaultState)

    // Memoize context value to avoid unnecessary re-renders
    const contextValue = useMemo(() => ({ settingsState, dispatch }), [settingsState])

    return <SettingsContext.Provider value={contextValue}>{children}</SettingsContext.Provider>
}
