import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from 'axios'

import {
    Task,
    GlossaryDict,
    WorkerId,
    SessionStatus,
    GlossaryTerm,
    TermRequestBody,
    SessionStats,
    SessionHealth,
    Label,
    TaskRequest,
    Transcript,
    LiveTranscriptionLayerId,
    LiveTranscriptionRole,
    TranscriptJSON,
    toTranscript,
    GlossaryDictJSON,
    toGlossaryDict,
    LabelsDictJSON,
    toLabelsDict,
    toGlossaryTerm,
    SpeakerJSON,
    SubmitSegmentPayload,
    Speaker,
    SpeakerShortJSON,
} from 'src/models'
import { assertIsDefined } from 'src/utils/assert'
import { getChatServerBaseURL } from 'src/utils/env'

import {
    WorkerStatusJSON,
    SessionStatusJSON,
    toSessionStatus,
    ErrorResponseBody,
    SessionStatsJSON,
    toStats,
    toTaskRequest,
    TaskRequestJSON,
    AuthenticateChatClientResponseJSON,
    SessionHealthJSON,
    toSessionHealth,
    toWorkerStatus,
    WorkerStatus,
} from './responses'

import { APIError, ErrorCode, IgnoredStatusCodes } from './errors'
import { mockStatic } from './mocks'
import { Hotkey, HotkeyType } from '../models/hotkeys'
import { toSpeakersResponse } from '../utils/speaker'

interface HttpClientOptions {
    baseUrl: string
    authToken?: string
}

export class HttpClient {
    private options: HttpClientOptions
    private client: AxiosInstance
    private workerId?: string

    static GATEWAY_ERROR_CODES = [502, 504]

    constructor(options: HttpClientOptions) {
        this.options = options

        const axiosConfig: AxiosRequestConfig = {
            baseURL: options.baseUrl,
        }

        if (options.authToken) {
            axiosConfig.headers = { Authorization: `Bearer ${options.authToken}` }
        }

        this.client = axios.create(axiosConfig)
    }

    // Authorization is possible either through cookie (in production) or through a direct token in Authorization header (with the backdoor)
    setAuthToken(token: string) {
        this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }

    setWorkerId(workerId: WorkerId) {
        this.workerId = workerId
    }

    updateBaseUrl(newBaseUrl: string) {
        this.client.defaults.baseURL = newBaseUrl
        this.options.baseUrl = newBaseUrl
    }

    async getTranscript(): Promise<Transcript> {
        const response = await this.get<TranscriptJSON>(`/transcript`)
        return toTranscript(response.data)
    }

    async postTranscript(payload: SubmitSegmentPayload): Promise<boolean> {
        const response = await this.post<TranscriptJSON>(`/transcript`, { payload })

        return Boolean(response.status === 200)
    }

    async joinSessionWithUserDetails(
        username: string,
        layerIds: LiveTranscriptionLayerId[],
    ): Promise<WorkerStatus> {
        // eslint-disable-next-line array-callback-return
        const permissions = layerIds.map<LiveTranscriptionRole>((layerId) => {
            switch (layerId) {
                case 'edit':
                    return 'trax_editor'
                case 'annotate':
                    return 'trax_annotator'
                case 'review':
                    return 'trax_reviewer'
                case 'customer_content_manager':
                    return 'customer_content_manager'
            }
        })

        const response = await this.post<WorkerStatusJSON>('/join', {
            username,
            permissions,
        })

        return toWorkerStatus(response.data)
    }

    async joinSessionWithToken(token?: string) {
        const response = await this.post<WorkerStatusJSON>('/join', {
            auth_token: token,
        })

        return toWorkerStatus(response.data)
    }

    async authenticateChatClientUser() {
        const { data } = await this.post<AuthenticateChatClientResponseJSON>(
            '/authenticate',
            null,
            getChatServerBaseURL(),
            true,
        )
        return data
    }

    async joinRestingQueue(): Promise<boolean> {
        assertIsDefined(this.workerId)
        const response = await this.post<{ success: boolean }>(
            `/workers/${this.workerId}/request_rest`,
            {
                worker_id: this.workerId,
            },
        )
        return response.data.success
    }

    async leaveRestingQueue(): Promise<boolean> {
        assertIsDefined(this.workerId)
        const response = await this.post<{ success: boolean }>(
            `/workers/${this.workerId}/cancel_rest_request`,
            {
                worker_id: this.workerId,
            },
        )
        return response.data.success
    }

    async getSessionStatus(): Promise<SessionStatus> {
        const response = await this.get<SessionStatusJSON>(`/status`)
        const status = toSessionStatus(response.data)
        return status
    }

    async getSessionAnalytics(platformWorkerId: number) {
        await this.get(`/analytics/transcriber/${platformWorkerId}`)
    }

    async requestNewTask(): Promise<TaskRequest> {
        assertIsDefined(this.workerId, 'Tried to request a task without a workerId.')

        const { data } = await this.post<TaskRequestJSON>('/tasks/request')
        return toTaskRequest(data)
    }

    async abortTask(taskId: string, reason: string): Promise<void> {
        assertIsDefined(this.workerId, 'Tried to abort a task without a workerId.')
        await this.post('/tasks/abort', { task_id: taskId, reason })
    }

    async publishTask(task: Task, data: any): Promise<void> {
        assertIsDefined(this.workerId)

        await this.post(`/tasks/submit`, data)
    }

    async autoSaveTask(taskId: string, data: any): Promise<void> {
        assertIsDefined(this.workerId)
        await this.post(`/tasks/${taskId}`, data)
    }

    async transcriptFinalize(): Promise<void> {
        assertIsDefined(this.workerId)
        await this.post(`/transcript/finalize`)
    }

    async getGlossary(): Promise<GlossaryDict> {
        const response = await this.get<GlossaryDictJSON>('/tags?type=glossary')
        return toGlossaryDict(response.data)
    }

    async getLabels(): Promise<Label[]> {
        const response = await this.get<LabelsDictJSON>('/tags?type=label')
        return toLabelsDict(response.data)
    }

    async addGlossaryTerm(data: GlossaryTerm): Promise<GlossaryTerm | null> {
        assertIsDefined(this.workerId)
        // omit id because its a fake one from the client
        const term: Omit<GlossaryTerm, 'id'> = { category: data.category, text: data.text }

        const requestBody: TermRequestBody = { type: 'glossary', glossary: term }
        const response = await this.post<{ id: string; success: boolean }>('/tags', requestBody)

        if (!response.data.success) {
            return null
        }
        return toGlossaryTerm(data, response.data.id)
    }

    async updateGlossaryTerm(term: GlossaryTerm): Promise<void> {
        assertIsDefined(this.workerId)
        await this.request('put', `/tags/${term.id}`, {
            type: 'glossary',
            glossary: {
                text: term.text,
                category: term.category,
            },
        })
    }

    async deleteGlossaryTerm(term: GlossaryTerm): Promise<void> {
        assertIsDefined(this.workerId)
        const requestBody = this.workerId ? { worker_id: this.workerId } : {}
        await this.request('delete', `/tags/${term.id}`, requestBody)
    }

    async getHotkeys(type: HotkeyType): Promise<Hotkey[]> {
        const response = await this.get<Hotkey[]>(`/user/hotkeys?type=${type}`)
        return response.data
    }

    async addHotkey(hotkey: Hotkey): Promise<Hotkey> {
        const response = await this.post<Hotkey>(`/user/hotkeys`, hotkey)
        return response.data
    }

    async deleteHotkey(id: string): Promise<boolean> {
        const response = await this.request<boolean>('delete', `/user/hotkeys/${id}`)
        return response.data
    }

    async updateHotkey(hotkey: Hotkey): Promise<Hotkey> {
        const response = await this.request<Hotkey>('put', `/user/hotkeys/${hotkey.id}`, hotkey)
        return response.data
    }

    async getSpeakers(): Promise<Speaker[]> {
        const response = await this.get<{ speakers: SpeakerJSON[] }>(`/speakers`)
        return toSpeakersResponse(response.data.speakers)
    }

    async createSpeaker(
        speaker: Omit<SpeakerShortJSON, 'id' | 'created_at'>,
    ): Promise<{ id: string; created_at: string }> {
        const response = await this.post(`/speakers`, speaker)
        return response.data
    }

    async updateSpeaker(speaker: Omit<SpeakerShortJSON, 'created_at'>): Promise<void> {
        await this.request('put', `/speakers/${speaker.id}`, speaker)
    }

    async exitSession(): Promise<void> {
        assertIsDefined(this.workerId)
        await this.patch(`/workers/${this.workerId}`, {
            worker_id: this.workerId,
            worker_state: 'LEFT',
        })
        return
    }

    async getSessionHealth(): Promise<SessionHealth> {
        const { data } = await this.get<SessionHealthJSON>('/health')
        return toSessionHealth(data)
    }

    async getSessionStats(): Promise<SessionStats> {
        assertIsDefined(this.workerId)
        const response = await this.get<SessionStatsJSON>(`/workers/${this.workerId}`)
        return toStats(response.data)
    }

    private async get<T = any>(uri: string, baseUrl?: string) {
        return this.request<T>('get', uri, baseUrl)
    }

    private post<T = any>(uri: string, data?: any, baseUrl?: string, withCredentials?: boolean) {
        return this.request<T>('post', uri, data, baseUrl, withCredentials)
    }

    private async patch<T = any>(
        uri: string,
        data?: any,
        baseUrl?: string,
        withCredentials?: boolean,
    ) {
        return this.request<T>('patch', uri, data, baseUrl, withCredentials)
    }

    @mockStatic({
        resourceId: (method, uri) => `${method.toUpperCase()}:${uri}`,
        delay: [200, 1_000],
    })
    private async request<T>(
        method: 'get' | 'post' | 'put' | 'delete' | 'patch',
        uri: string,
        data?: any,
        baseUrl = this.options.baseUrl,
        withCredentials?: boolean,
    ) {
        try {
            const { pathname: baseUrlPathName } = new URL(baseUrl)
            const url = new URL(uri, baseUrl)

            let basePath = baseUrlPathName
            if (basePath.endsWith('/')) {
                basePath = basePath.slice(0, -1)
            }
            url.pathname = basePath + url.pathname

            return await this.client.request<T>({ method, url: url.href, data, withCredentials })
        } catch (e: any) {
            const apiError = HttpClient.enhanceError(e)

            if (apiError.code === ErrorCode.Unknown && apiError.message !== 'Network Error') {
                switch (apiError.httpCode) {
                    case 431: {
                        window.Rollbar?.error(
                            `${method} ${
                                new URL(uri, baseUrl).href
                            }: Error 431 Request Header Fields Too Large (Likely due to auth token)`,
                            e,
                        )
                        break
                    }
                    default: {
                        const title = HttpClient.GATEWAY_ERROR_CODES.includes(apiError.code)
                            ? 'Gateway Error'
                            : 'Server Error'

                        window.Rollbar?.error(`${title}: ${apiError.message}`, apiError)
                    }
                }
            }

            throw apiError
        }
    }

    private static enhanceError(error: any): APIError {
        const axiosError = error as AxiosError<ErrorResponseBody>
        if (axiosError.response) {
            const payload = axiosError.response.data
            const errorData = payload.error
            const requestId = axiosError.request.getResponseHeader('X-Request-Id')

            if (errorData) {
                return new APIError(
                    errorData.code,
                    errorData.user_message,
                    errorData.critical,
                    requestId,
                    payload,
                )
            } else if (IgnoredStatusCodes.includes(axiosError.response.status)) {
                return new APIError(
                    ErrorCode.IgnoredStatusCodeError,
                    `${axiosError.config.method} ${axiosError.config.url} - ${axiosError.response.statusText}`,
                    false,
                    requestId,
                    undefined,
                    Number(axiosError.code),
                )
            } else {
                return APIError.unknownError(
                    'Unknown server error',
                    requestId,
                    axiosError.response.status,
                )
            }
        } else if (axiosError.request) {
            // The request was made but no response was received. `error.request` is an instance of XMLHttpRequest
            return APIError.unknownError(
                axiosError.message ?? 'Unknown error occurred while making request',
            )
        } else {
            // Something happened in setting up the request that triggered an Error
            return APIError.unknownError(
                axiosError?.message ?? 'Unknown error occurred on request setup',
            )
        }
    }
}
