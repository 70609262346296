import { useCallback } from 'react'
import styled, { css } from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'
import { Icon, Intent } from '@blueprintjs/core'
import { Task } from 'src/models'
import { TaskPublishType } from 'src/analytics/client'
import { useToast } from 'src/components/Toasts/ToastContext'
import {
    useIsTaskMachineActive,
    useTaskMachine,
} from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { appHotkeys } from 'src/utils/hotkeys'

const COUNTDOWN_DURATION_MS = 15 * 1000
const PRE_COUNTDOWN_DURATION = 2000
const BLINK_COUNT = 3

interface LinkProps {
    active: boolean
    preCountdownStarted: boolean
    publish_button_display_position?: string
}

const HeadLink = styled.div<LinkProps>`
    display: ${ifProp(
        { publish_button_display_position: 'header' },
        css`
            display: flex;
        `,
        'none',
    )};
    text-decoration: none;
    cursor: ${ifProp('active', 'pointer', 'not-allowed')};
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }

    ${ifProp(
        'preCountdownStarted',
        css`
            animation: blink ${PRE_COUNTDOWN_DURATION / BLINK_COUNT}ms ease-out infinite;
        `,
    )};

    @keyframes blink {
        0% {
            color: ${palette('navy', 2)};
        }

        50% {
            color: ${palette('blue', 2)};
        }

        100% {
            color: ${palette('cloudBlue', 2)};
        }
    }

    ${ifProp(
        'active',
        css`
            color: ${palette('navy', 0)};
            transition: background-color 500ms ease-in;
        `,
    )}
`

const getMessageForEarlySubmission = (task?: Task): string => {
    switch (task?.type) {
        case 'transcription':
            return 'Please listen to the entire task before you submit.'
        default:
            return 'You cannot submit this task yet.'
    }
}

export const PublishHeaderLink = () => {
    const addToast = useToast()
    const isTaskMachineActive = useIsTaskMachineActive()
    const [{ context }, sendTaskEvent] = useTaskMachine([
        'allowedToSubmit',
        'elapsed',
        'timerDuration',
    ])

    const { allowedToSubmit, elapsed, timerDuration, task } = context
    let publish_button_display_position
    if (isTaskMachineActive && task && task?.type === 'transcription') {
        publish_button_display_position = task?.payload?.controls?.publishButton?.position
    }
    const timeLeft = timerDuration - Math.floor(elapsed)
    const countdownStarted = timeLeft <= COUNTDOWN_DURATION_MS / 1000
    const preCountdownStarted =
        !countdownStarted && timeLeft <= (COUNTDOWN_DURATION_MS + PRE_COUNTDOWN_DURATION) / 1000

    const publish = useCallback(
        (publishType: Exclude<TaskPublishType, 'Timeout'>) => {
            if (!allowedToSubmit) {
                addToast({
                    intent: Intent.NONE,
                    icon: <Icon icon="issue" color="#c23030" />,
                    message: getMessageForEarlySubmission(task),
                })
                return
            }

            sendTaskEvent({ type: 'SUBMIT', autoSubmit: false, publishType })
        },
        [addToast, allowedToSubmit, sendTaskEvent, task],
    )
    useMousetrap(appHotkeys.publishTask, () => publish('Keyboard'), {
        label: 'Publish Task',
        preventDefault: true,
    })

    return (
        <HeadLink
            onClick={() => publish('Mouse')}
            data-testid={'publish-button'}
            active={allowedToSubmit}
            preCountdownStarted={preCountdownStarted}
            publish_button_display_position={publish_button_display_position}
        >
            Publish
        </HeadLink>
    )
}
