import { useCallback } from 'react'
import { Transforms, Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import { Intent, Icon } from '@blueprintjs/core'

import { useMousetrap } from 'src/hooks/useMousetrap'
import { useToast } from 'src/components/Toasts/ToastContext'
import { TAG_MAX_CHARS_LENGTH } from 'src/components/Glossary/useGlossaryList'

import { useEditorMeta, useEditorPopup } from '../../EditorContext'
import { Tag } from './Tag'
import { Suggestion } from '../withSuggestions/Suggestion'
import { Block } from 'src/components/Editor/plugins/withTranscript'
import { ActionTriggerSource, useAnalytics } from 'src/analytics'
import { appHotkeys } from 'src/utils/hotkeys'
import { useIsReadOnlyMode } from 'src/components/Session/live/useIsReadOnlyMode'

/**
 * This hook adds the keyboard shortcut "alt+/" for adding/removing unclear tags.
 * (The context menu item for unclear tags is added in the useTagsContextMenu hook)
 */
export function useEditorUnclears() {
    const editor = useSlateStatic()
    const analytics = useAnalytics()
    const { popupRange, closePopup } = useEditorPopup()
    const addToast = useToast()
    const { editorMode } = useEditorMeta()
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const isEditorInGlossersMode = editorMode === 'glossers'

    const handleUnclear = useCallback(() => {
        if (isRealTimeReadOnly) return
        if (isEditorInGlossersMode) return

        const at = Block.getSanitizedRange(editor) || popupRange?.current

        if (at) {
            const string = Editor.string(editor, at)

            if (string && string.length > TAG_MAX_CHARS_LENGTH) {
                addToast({
                    intent: Intent.NONE,
                    icon: <Icon icon="issue" color="#c23030" />,
                    message: (
                        <span>
                            <strong>{string.slice(0, 30)}...</strong>
                            could not be added, an unclear tag can hold up to 50 characters.
                        </span>
                    ),
                })
                return
            }
            Transforms.select(editor, at)
            Suggestion.unwrapSuggestion(editor, { at })

            if (Tag.hasTag(editor)) {
                Tag.unwrapTag(editor)
            } else {
                Tag.insertTag(editor, { type: 'unclear' })
                analytics?.sendMarkUnclear(ActionTriggerSource.KEYBOARD_SHORTCUT)
            }

            closePopup()
        }
    }, [
        isEditorInGlossersMode,
        editor,
        popupRange,
        closePopup,
        addToast,
        analytics,
        isRealTimeReadOnly,
    ])

    useMousetrap([appHotkeys.markUnclear, appHotkeys.markClear], handleUnclear, {
        label: 'Text: Toggle unclear',
        preventDefault: true,
    })
}
