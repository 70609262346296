import { EventAction, EventsActionType } from 'src/components/Editor/plugins/withEvents/withEvents'
import { EventsList } from 'src/models/events'
import { useEventsMarking } from 'src/state/EventsProvider'
import { useFeatureFlag } from 'src/state/FeatureFlagProvider'

export const useUpdateEditorEventList = () => {
    const { setEventsList } = useEventsMarking()
    const eventsMarkingFlag = useFeatureFlag('add_events_marking')
    const eventsLibTabEnabled = useFeatureFlag('events_list_tab')

    const handleUpdateAndInsertEventBlock = (props: EventAction) => {
        setEventsList((prev: EventsList[]) => {
            // Find if an event with the same `index` already exists
            const eventIndex = prev.findIndex((event) => {
                if (props.type === EventsActionType.UPSERT && props.event) {
                    return event.index === props.event.index
                }
                return false
            })

            // If the event exists, check if the section has changed
            if (props.type === EventsActionType.UPSERT && props.event && eventIndex !== -1) {
                const existingEvent = prev[eventIndex]

                // If the section has changed, update the event
                if (existingEvent.section !== props.event.section && props.event.section !== '') {
                    const updatedList = [...prev]
                    updatedList[eventIndex] = {
                        ...existingEvent, // Keep other properties (like mediaTimeCode) unchanged
                        section: props.event.section, // Update the section
                    }

                    return updatedList // Return the updated list with the modified event
                } else if (
                    existingEvent.section !== props.event.section &&
                    props.event.section === ''
                ) {
                    // If the section has been removed, remove the event from the list but keep the subsequent blockIndex unchanged
                    const updatedList = prev.filter((event) => event.index !== props.event.index)
                    return updatedList
                }

                return prev // If section hasn't changed, return the previous list
            }

            // If the event doesn't exist, add the new event to the list
            const updatedList = [
                ...prev,
                {
                    index:
                        props.type === EventsActionType.UPSERT && props.event && props.event.index,
                    mediaTimeCode:
                        props.type === EventsActionType.UPSERT &&
                        props.event &&
                        props.event.mediaTimeCode,
                    section:
                        props.type === EventsActionType.UPSERT &&
                        props.event &&
                        props.event.section,
                } as EventsList,
            ]

            return updatedList.sort((a, b) => a.index - b.index)
        })
    }

    const handleDeleteEventBlock = (props: EventAction) => {
        setEventsList((prev: EventsList[]) => {
            const eventIndex = prev.findIndex(
                (event) =>
                    props.type === EventsActionType.DELETE &&
                    props.blockIndex &&
                    event.index === props.blockIndex,
            )
            if (props.type === EventsActionType.DELETE && props.blockIndex && eventIndex !== -1) {
                const updatedList = prev
                    // Remove the item with the matching index
                    .filter((item) => item.index !== props.blockIndex)
                    // Decrement the index if it's greater than the deleted index
                    .map((item) =>
                        item.index > props.blockIndex ? { ...item, index: item.index - 1 } : item,
                    )

                return updatedList
            }
            return prev
        })
    }

    const handleSplitEventBlock = (props: EventAction) => {
        setEventsList((prev: EventsList[]) => {
            const updatedList = [...prev]
                // Increment the index if it's greater than the split index
                .map((item) =>
                    props.type === EventsActionType.SPLIT &&
                    props.blockIndex &&
                    item.index > props.blockIndex
                        ? { ...item, index: item.index + 1 }
                        : item,
                )

            return updatedList
        })
    }

    const handleMergeEventBlock = (props: EventAction) => {
        setEventsList((prev: EventsList[]) => {
            const updatedList = prev
                // Decrement the index if it's greater than the deleted index
                .map((item) =>
                    props.type === EventsActionType.MERGE &&
                    props.blockIndex &&
                    item.index >= props.blockIndex
                        ? { ...item, index: item.index - 1 }
                        : item,
                )

            return updatedList
        })
    }

    // this is responsible for updating the events list in the state manager
    // whenever the editor content changes (e.g. when a section is added, removed, or modified)
    // or when the events marking feature flag changes.
    // Also updates the events list when transcript comes with section/event in it
    const updateEventsList = (props: EventAction) => {
        if (!eventsMarkingFlag && !eventsLibTabEnabled) return

        switch (props.type) {
            // if the block at blockIndex is a block that was updated or inserted
            case EventsActionType.UPSERT: {
                handleUpdateAndInsertEventBlock(props)
                break
            }

            // if the block at blockIndex is a block that was merged or the user removes the event from the block
            // we need to update all subsequent index values by decrementing them by 1
            case EventsActionType.DELETE: {
                handleDeleteEventBlock(props)
                break
            }

            // if the block at blockIndex is a block that was split,
            // we need to update all subsequent index values by incrementing them by 1
            case EventsActionType.SPLIT: {
                handleSplitEventBlock(props)
                break
            }

            // if the block at blockIndex is a block that was merged
            case EventsActionType.MERGE: {
                handleMergeEventBlock(props)
                break
            }
        }
    }

    const initialEventsList = (eventlist: EventsList[]) => {
        setEventsList(eventlist)
    }

    return { updateEventsList, initialEventsList }
}
